import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FlexCenter from './flex-center';
import Subtitle from './subtitle';
import Title from './title';

const TitleContainer = styled.div({
  [respondTo('xs', 'md')]: { minHeight: '280px' },
});

const Decoration = styled.img({
  position: 'absolute',
  [respondTo('xs', 'md')]: { left: 'calc(50% - 25px)', bottom: '-136px' },
  [respondTo('md')]: { left: '-70px', top: '13px' },
});

const ZigZagDecoration: React.FC = () => {
  const zigzag = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "misc_zigzag.svg" }) {
        publicURL
      }
    }
  `);

  return <Decoration src={zigzag.image.publicURL} />;
};

interface FeaturedLandingTitleProps {
  title: string;
  subtitle: string;
}

const FeaturedLandingTitle: React.FC<FeaturedLandingTitleProps> = ({ title, subtitle }) => {
  return (
    <TitleContainer>
      <div style={{ display: 'flex' }}>
        <FlexCenter>
          <Title
            style={{
              marginBottom: '16px',
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Title>
        </FlexCenter>
      </div>
      <div style={{ display: 'flex' }}>
        <FlexCenter>
          <div style={{ position: 'relative' }}>
            <Subtitle
              style={{
                marginTop: '0',
                marginBottom: '48px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              {subtitle.split('\\n').map((line, i, lines) => (
                <>
                  {i === 0 ? (
                    <span style={{ position: 'relative' }}>
                      <ZigZagDecoration />
                      {line}
                    </span>
                  ) : (
                    line
                  )}
                  {i < lines.length - 1 ? <br /> : null}
                </>
              ))}
            </Subtitle>
          </div>
        </FlexCenter>
      </div>
    </TitleContainer>
  );
};

export default FeaturedLandingTitle;
