import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import CarLeadForm, { CarLeadData } from '../../../components/car-lead-form';
import FlexCenter from '../../../components/flex-center';
import Footer from '../../../components/footer';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: CarLeadData = {
  car: '',
  name: '',
  email: '',
  phone: '',
};

const FeaturedLandingUy: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "cotizar-seguros-de-autos_sm" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "cotizar-seguros-de-autos_lg" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);

  return (
    <Layout gtmId="GTM-M9T3L4F">
      <SEO
        title="Cotizar Seguros de Autos"
        siteTitle="123Seguro Uruguay"
        description="En 123Seguro vas a poder Cotizar Seguros de autos y encontrar un mejor precio para la cobertura de tu auto, ¡Cotizá y te contactamos por WhatsApp!"
      />
      <Hero
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
        title="Cotizar seguros de autos"
        subtitle="Contratá el seguro de\nauto a tu medida"
      />
      <Container>
        <FlexCenter>
          <Column desktop="6">
            {submitted ? (
              <SuccessNotice
                noticeText={`Hemos recibido tu solicitud de cotización para tu ${leadInfo.car}. Nos contactaremos con vos por WhatsApp (${leadInfo.phone}) o por mail (${leadInfo.email}).`}
                solicitudText="¡Solicitud enviada!"
                verifyText="¿No son esos tus datos?"
                onRequestChange={() => submit(false)}
                changeText="Modificarlos"
              />
            ) : (
              <CarLeadForm
                {...leadInfo}
                sampleCar="Ej.: Renault Kwid 2019"
                sampleName="Ej.: Ariel Martínez"
                sampleEmail="Ej.: nombre@dominio.com"
                samplePhone="Ej.: 22334455"
                labelCar="MARCA, MODELO Y AÑO DE TU AUTO"
                labelName="NOMBRE"
                labelEmail="CORREO ELECTRÓNICO"
                labelPhone="TELÉFONO CELULAR"
                minPhoneLength={4}
                maxPhoneLength={12}
                countryCode="uy"
                errorMessage="Por favor revisá los campos con error"
                missingCarErrorMessage="Ingresá tu vehículo; ej.: Renault Kwid 2019"
                missingNameErrorMessage="Ingresá tu nombre; ej.: Ariel Martínez"
                missingEmailErrorMessage="Ingresá tu email; ej.:nombre@dominio.com"
                invalidEmailErrorMessage="Ingresá un email válido; ej.: nombre@dominio.com"
                missingPhoneErrorMessage= "Ingresá tu teléfono; ej.: 22334455"
                invalidPhoneErrorMessage="Ingresá un teléfono válido; ej.: 22334455"
                focusEmailMessage="Correo al que enviaremos tu póliza"
                focusPhoneMessage="Celular para la gestión de tu seguro"
                submitbutton="Solicitar cotización"
                onRegistration={(formValues) => {
                  setLeadInfo(formValues);
                  submit(true);
                }}
              ></CarLeadForm>
            )}
          </Column>
        </FlexCenter>
        <Footer />
      </Container>
    </Layout>
  );
};

export default FeaturedLandingUy;
