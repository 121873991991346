import { box, respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';

const Form = styled.form({
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type="number"]': {
    MozAppearance: 'textfield',
  },
  padding: '32px 16px',
  [respondTo('md')]: {
    ...box,
    padding: '32px 96px',
  },
});

export default Form;
