import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const LogoImg = styled.img({
  [respondTo('xs', 'md')]: { height: '32px' },
});

const Logo: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo_123seguro.svg" }) {
        publicURL
      }
    }
  `);

  return <LogoImg src={data.image.publicURL}></LogoImg>;
};

export default Logo;
