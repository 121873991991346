import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';

const Backgroundcontainer = styled.div({
  position: 'absolute',
  left: '0',
  width: '100%',
  zIndex: -1,
  background: 'rgb(57, 73, 171)',
  [respondTo('xs', 'md')]: {
    height: '280px',
    top: '49px',
  },
  [respondTo('md')]: {
    height: '400px',
    top: '61px',
  },
});

interface BackgroundProps {
  desktopBackground: FluidObject;
  mobileBackground: FluidObject;
}

const Background: React.FC<BackgroundProps> = ({ desktopBackground, mobileBackground }) => (
  <Backgroundcontainer>
    <Img
      style={{
        margin: '0 auto',
        height: '100%',
      }}
      fluid={[mobileBackground, { ...desktopBackground, media: `(min-width: 768px)` }]}
    />
  </Backgroundcontainer>
);

export default Background;
