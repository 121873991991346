import { respondTo, subtitleFont } from '@123-front/ui-kit';
import styled from '@emotion/styled';

const Title = styled.h1({
  ...subtitleFont('md', 'sans'),
  textTransform: 'uppercase',
  [respondTo('xs', 'md')]: { marginTop: '32px' },
  [respondTo('md')]: { marginTop: '60px' },
});

export default Title;
