import { FluidObject } from 'gatsby-image';
import React from 'react';
import Background from './background';
import FeaturedLandingTitle from './featured-landing-title';

interface HeroProps {
  desktopBackground: FluidObject;
  mobileBackground: FluidObject;
  title: string;
  subtitle: string;
}

const Hero: React.FC<HeroProps> = ({ desktopBackground, mobileBackground, title, subtitle }) => (
  <>
    <Background {...{ desktopBackground, mobileBackground }} />
    <FeaturedLandingTitle {...{ title, subtitle }} />
  </>
);

export default Hero;
