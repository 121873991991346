import { baseStyles, normalize } from '@123-front/ui-kit';
import useGTM from '@elgorditosalsero/react-gtm-hook';
import { Global } from '@emotion/core';
import React, { ReactElement, useEffect } from 'react';
import LandingHeader from './landing-header';

interface LayoutProps {
  gtmId: string;
  footerComponent?: ReactElement;
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ gtmId, footerComponent, children }) => {
  const { init } = useGTM();
  useEffect(() => init({ id: gtmId }), []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Global styles={[normalize, baseStyles]}></Global>
      <LandingHeader />
      <main style={{ flex: 1 }}>{children}</main>
      {footerComponent && footerComponent}
    </div>
  );
};

export default Layout;
