import { bodyFont, Divider, respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const LegalText = styled.p({ ...bodyFont('sm'), textAlign: 'center' });
const FooterContainer = styled.div({
  [respondTo('md')]: { marginTop: '80px' },
});

interface FooterProps {
  text?: string;
}

const Footer: React.FC<FooterProps> = ({ text }) => (
  <FooterContainer>
    <Divider />
    <LegalText style={{ marginTop: 24 }}>{text}</LegalText>
  </FooterContainer>
);

Footer.defaultProps = {
  text: 'Copyright © 2020 123Seguro. Todos los derechos reservados.',
};

export default Footer;
