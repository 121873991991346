import { Header } from '@123-front/ui-kit';
import React from 'react';
import FlexCenter from './flex-center';
import Logo from './logo';

const LandingHeader: React.FC = () => (
  <Header menuEnabled={false}>
    <FlexCenter>
      <Logo />
    </FlexCenter>
  </Header>
);

export default LandingHeader;
