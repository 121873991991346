import {
  Alert,
  Button,
  Input,
  isValidEmail,
  isValidNumber,
  isValidRequired
} from '@123-front/ui-kit';
import Axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Form from './form';
import './form.css';


export interface CarLeadData {
  car: string;
  email: string;
  name: string;
  phone: string;
}

const initialErrors = {
  car: null,
  name: null,
  email: null,
  phone: null,
};

interface FormProps extends CarLeadData {
  sampleCar: string;
  sampleEmail: string;
  sampleName: string;
  samplePhone: string;
  labelCar: string;
  labelName: string;
  labelEmail: string;
  labelPhone: string;
  minPhoneLength: number;
  maxPhoneLength: number;
  countryCode: string;
  errorMessage: string;
  missingCarErrorMessage: string;
  missingNameErrorMessage: string;
  missingEmailErrorMessage: string;
  invalidEmailErrorMessage: string;
  missingPhoneErrorMessage: string;
  invalidPhoneErrorMessage: string;
  focusEmailMessage: string;
  focusPhoneMessage: string;
  vehicleType?: 'car' | 'truck';
  submitbutton: string;
  onRegistration: (formData: CarLeadData) => void;
}

const CarLeadForm: React.FC<FormProps> = ({ onRegistration, ...props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiDomain
          }
        }
      }
    `,
  );
  const [car, setCar] = useState(props.car);
  const [email, setEmail] = useState(props.email);
  const [name, setName] = useState(props.name);
  const [phone, setPhone] = useState(props.phone);
  const [errors, setErrors] = useState(initialErrors);
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isValid = (validationResults) => Object.values(validationResults).every((error) => error === '');
  const validate = () => {
    const newErrors = {
      car: !isValidRequired(car) ? props.missingCarErrorMessage : '',
      name: !isValidRequired(name) ? props.missingNameErrorMessage : '',
      email: !isValidRequired(email)
        ? props.missingEmailErrorMessage
        : !isValidEmail(email)
        ? props.invalidEmailErrorMessage
        : '',
      phone: !isValidRequired(phone)
        ? props.missingPhoneErrorMessage
        : !isValidNumber(phone) ||
          phone.length < props.minPhoneLength ||
          phone.length > props.maxPhoneLength
        ? props.invalidPhoneErrorMessage
        : '',
    }
    setErrors(newErrors);
    return newErrors;
  };
  return (
    <Form noValidate>
      <Input
        label={props.labelCar}
        placeholder={props.sampleCar}
        value={car}
        error={errors.car}
        onChange={({ target }) => setCar(target.value)}
      ></Input>
      <Input
        label={props.labelName}
        placeholder={props.sampleName}
        value={name}
        error={errors.name}
        onChange={({ target }) => setName(target.value)}
      ></Input>
      <Input
        label={props.labelEmail}
        placeholder={props.sampleEmail}
        value={email}
        type="email"
        info={props.focusEmailMessage}
        error={errors.email}
        onChange={({ target }) => setEmail(target.value)}
      ></Input>
      <Input
        className={`phone-${props.countryCode}`}
        label={props.labelPhone}
        placeholder={props.samplePhone}
        value={phone}
        type="number"
        info={props.focusPhoneMessage}
        error={errors.phone}
        onChange={({ target }) => setPhone(target.value)}
      ></Input>
      {submitted && !isValid(errors) ? (
        <div style={{ paddingBottom: '24px' }}>
          <Alert type="error" text={props.errorMessage}></Alert>
        </div>
      ) : null}
      <Button
        block
        disabled={processing}
        onClick={async () => {
          setSubmitted(true);
          const validationResults = validate();
          if (isValid(validationResults)) {
            setProcessing(true);
            try {
              await Axios.post(
                `//${site.siteMetadata.apiDomain}/api/quote?product=${props.vehicleType || 'car'}`,
                {
                  car,
                  name,
                  phone,
                  email,
                  countryCode: props.countryCode,
                  url: document.URL,
                },
              );
              setProcessing(false);
              window.scrollTo({ top: 0, behavior: 'smooth' });
              onRegistration({ car, name, phone, email });
            } catch (_) {
              setProcessing(false);
            }
          }
        }}
      >
        {props.submitbutton}
      </Button>
    </Form>
  );
};

CarLeadForm.defaultProps = {
  vehicleType: 'car',
};

export default CarLeadForm;
