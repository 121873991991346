import {
  baseLink,
  bodyFont,
  box,
  colors,
  colorTheme,
  Divider,
  respondTo,
  Row,
  titleFont,
} from '@123-front/ui-kit';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FlexCenter from './flex-center';

const NoticeContainer = styled.div({
  textAlign: 'center',
  padding: '32px 16px',
  h2: {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    marginBottom: 24,
    marginTop: 10,
  },
  [respondTo('md')]: { ...box, h2: { marginBottom: 16 } },
});

const NoticeText = styled.p(bodyFont('md'));

const SuccessImg = styled.img({
  [respondTo('xs', 'md')]: { width: '100px' },
  [respondTo('md')]: { width: '168px' },
});

const ChangeLink = styled.a({ ...baseLink, cursor: 'pointer' });

interface NoticeProps {
  noticeText: string;
  solicitudText: string;
  verifyText: string;
  onRequestChange: Function;
  changeText: string;
}

const SuccessNotice: React.FC<NoticeProps> = ({ onRequestChange, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "animation_success.gif" }) {
        publicURL
      }
    }
  `);

  return (
    <>
      <NoticeContainer>
        <Row>
          <FlexCenter>
            <SuccessImg src={data.image.publicURL}></SuccessImg>
          </FlexCenter>
        </Row>
        <h2>{props.solicitudText}</h2>
        <NoticeText style={{ marginBottom: '40px', color: colorTheme.mute }}>
          {props.noticeText}
        </NoticeText>
        <Divider />
        <NoticeText style={{ paddingTop: 13, marginBottom: 0 }}>
          {props.verifyText}{' '}
          <ChangeLink onClick={() => onRequestChange()}>{props.changeText}</ChangeLink>
        </NoticeText>
      </NoticeContainer>
    </>
  );
};

export default SuccessNotice;
